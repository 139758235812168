<!-- 上传汇款凭证弹窗 -->
<template>
  <el-dialog
    width="50%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'对账核销':'对账核销'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="130px">

      <div style="border-bottom: 1px solid #eeeeee;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">账单信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="客户名称：">
              <span>{{details.info.channel_name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="对账周期：">
              <span v-for="(item,index) in details.info.period" :key="index" style="margin-right: 10px;">{{item}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="订单总量：">
              <span>{{details.info.order_count}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同价：">
              <span>{{details.info.contract_price}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="整体补扣款原因：">
              <span>{{details.info.entirety_repair_reason}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应收款：">
              <span style="color: #FF9B05;">{{details.info.receivable}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">付款信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="开户行：">
              <span>{{details.payment_proof.tax_number}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户名：">
              <span>{{details.payment_proof.bank_number}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="账号：">
              <span>{{details.payment_proof.account_holder}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票：">
              <div style="display: flex;align-items: center;" @click="open()">
                <span style="color: #FF9B05;">下载</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="付款凭证：">
              <div style="display: flex;align-items: center;" @click="open1()">
                <span style="color: #FF9B05;">下载</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>


    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确认核销
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import {get_payment_proof, upload_payment, verification} from "@/api/finance";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/finance',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      // 上传假数据展示
      fileList: [],

      //
      details:{},

    };
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getDetails();

  },
  methods: {
    //获取付款信息
    getDetails(){
      get_payment_proof(this.data.id).then(res => {
        this.details = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          verification(this.data.id).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //发票下载
    open(){
      window.open(this.details.info.invoice);
    },

    //凭证下载
    open1(){
      window.open(this.details.info.payment_proof);
    },



  }
}
</script>

<style scoped lang="scss">

</style>
