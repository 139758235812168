import { render, staticRenderFns } from "./customerDirectory-edit.vue?vue&type=template&id=2e2cb844&scoped=true&"
import script from "./customerDirectory-edit.vue?vue&type=script&lang=js&"
export * from "./customerDirectory-edit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e2cb844",
  null
  
)

export default component.exports