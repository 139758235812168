<!-- 客户目录弹窗 -->
<template>
  <el-dialog
      width="50%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'客户目录':'客户目录'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="130px">

      <div>
        <el-form-item label="客户名称：">
          <div style="display: flex;align-items: center;">
            <el-input
                placeholder="请输入客户名称"
                v-model="form.input"
                clearable>
            </el-input>
            <el-button type="primary" icon="el-icon-search" style="background: #31334B!important;border-color: #31334B!important;color: #FFFFFF!important;margin-left: 20px;">搜索</el-button>
          </div>
        </el-form-item>
      </div>

      <div style="margin-bottom: 20px;">
        <!-- 数据表格 -->
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :total="total"
            :customsFromWhere="where"
            :loading="loading"
            @select="select"
            @ChangeSize="ChangeSize"
            @currentChange="currentChange">
        </my-table>
      </div>

    </el-form>


    <!--<div slot="footer">-->
    <!--  <el-button-->
    <!--      @click="updateVisible(false)">取消-->
    <!--  </el-button>-->
    <!--  <el-button-->
    <!--      style="margin-left: 20px;"-->
    <!--      type="primary"-->
    <!--      :loading="loading"-->
    <!--      @click="save">确定-->
    <!--  </el-button>-->
    <!--</div>-->

  </el-dialog>
</template>

<script>

// 引入的接口
import {
  getfacilitatorApi,
} from '@/api/custom'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 表格搜索条件
      where: {
        facilitator_name:'',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          label: "客户名称",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "客户地址",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "订单总数",
          prop: "id",
          isShow: true
        },
        {
          label: "订单总金额",
          prop: "id",
          isShow: true
        },
      ],

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    // 获取列表
    getList(){
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading1 = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading1 = false;
      })
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        facilitator_name:'',
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },


  }
}
</script>

<style scoped lang="scss">

</style>
